import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import dSocialBack from "./../../assets/icons/dSocialBack.png";
import l1feMinistry from "./../../assets/images/l1feMinistry.png";
import life from "./../../assets/icons/lifeGroup.png";
import { useNavigate } from "react-router-dom";


const DashboardPage = () => {

    const navigate = useNavigate();


    const handleLogout = () => {
        localStorage.removeItem("accessToken");
        navigate("/login");
    }
    return (
        <Container fluid className="vh-100 login-wrapper wrapper">
            <Row className="h-100">
                <Col xl={4} lg={5} md={6} className="left-col d-none d-md-block">
                    <div className="w-50 mx-auto text-center left-inner-block d-flex flex-column align-items-center justify-content-end h-100">
                        <img className="img-social-back" src={dSocialBack} alt="back" />
                        <h1>Here is your voice back</h1>
                        <div className="ministry-block">
                            <img 
                                src={l1feMinistry} 
                                alt="l1fe_ministry" 
                                style={{ width: '150px', height: '41px' }} 
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={8} lg={7} md={6} className="right-col">
                    <Container className="h-100">
                        <Row className="h-100">
                            <Col lg={{ span: 6, offset: 1 }} className="d-flex">
                                <div className="w-100">
                                    <div className='d-flex justify-content-between align-items-center mt-3 mb-3'>
                                        <div className="icon-block">
                                            <img 
                                                src={life} 
                                                alt="life" 
                                                className="tab_desk" 
                                                style={{ width: '100px'}}
                                            />
                                        </div>
                                        <button className="btn btn-danger btn-lg btn-block btn-login"
                                            onClick={handleLogout}
                                        >Logout</button>
                                    </div>
                                    <h2>Welcome to the L1FE Dashboard</h2>
                                    <p>Coming Soon</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default DashboardPage;