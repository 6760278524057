export const API = {
  registerUser: "/dotbot/create",
  registerSocialUser: "/dotbot/oAuth/register",
  loginUser: "/dotbot/authenticate",
  forgotPassword: "/dotbot/forgot_password",
  resetPassword: "/dotbot/reset_password",
  emailVerification: "/dotbot/send-verify-mail",
  verifyEmail: "/dotbot/verify-mail",
  checkEmail: "/dotbot/check-email",
  authOAuthCheck: "/dotbot/oAuth/check",
  getCountries: "/dotbot/get_countries",
};


