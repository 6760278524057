import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import instance from "../../../utils/axios";
import { API } from "../../../utils/apiendpoints";
import { app } from "../../../utils/firebase";


import twitter from "./../../../assets/icons/twitter.png";
import emailIcon from "./../../../assets/icons/emailIcon.png";

import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";

import Input from "../../shared/Input";
import { toast } from "react-toastify";
const twitterProvider = new TwitterAuthProvider();
const auth = getAuth(app);

const Step1 = ({ setStep, children }) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [socialId, setSocialId] = useState(false);
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });

  const [googleEmail, setgoogleEmail] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    if (queryParams?.get("googleEmail")) {
      setgoogleEmail(true);
      setValue("email", queryParams?.get("googleEmail"));
    }
  }, []);

  const onFormSubmit = (data) => {
    trigger()
      .then((res) => {
        if (res) {
          setStep((prev) => prev + 1);
          navigate({
            search: new URLSearchParams(data).toString(),
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const verifySocialAccount = async (userData, email) => {
    await instance
      .post(`${API.authOAuthCheck}`, {
        email,
        token: userData.accessToken,
      })
      .then((res) => {
        if (res?.data?.data === null) {
          setgoogleEmail(true);
          setValue("email", email);
          onFormSubmit({ email: email })
        }
        else if (res?.data?.status) {
          toast.success("User already exist please login");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.status === false) {
          setValue("email", userData?.email);
          navigate(`/signup?googleEmail=${userData?.email}`);
        }
        console.log(err);
      });
  };

  const EmailVerify = async (event) => {
    const enteredEmail = event.target.value;
    if (enteredEmail.match(/^[\w.+-]+@([\w-]+\.)+[\w-]{2,}$/)) {
      try {
        await instance.get(`${API.checkEmail}/${enteredEmail}`).then((res) => {
          if (!res?.error) {
            if (res?.data?.status === true) {
              setError("email", {
                type: "manual", // You can use 'manual' type for custom errors
                message: `${res?.data?.message}`,
              });
            } else {
              setError("email", null);
            }
            console.log("---email msg---", res);
          }
        });
        // setIsEmailVerified(response.data.message);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };


  const signinwithTwitter = async () => {
    setLoader(true);
    signInWithPopup(auth, twitterProvider)
      .then((result) => {
        if (result._tokenResponse.email === undefined || result._tokenResponse.email === null) {
          return toast.error("Please add email in your twitter account and try again");
        }
        setLoader(false);
        setSocialId(true);
        verifySocialAccount(result.user, result._tokenResponse.email);
      }).catch((error) => {
        setLoader(false);
        console.log(error)
      })
  }


  return (
    <>
      <div className="social-group">
        <div className="social-signup-btn" onClick={signinwithTwitter}>
          <img src={twitter} alt="Twitter" width={25} height={25} /> <span>Continue with Twitter</span>
        </div>
      </div>
      {/* format */}
      <div
        style={{
          width: "100%",
          height: 20,
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 20,
          display: "inline-flex",
        }}
      >
        <div
          style={{
            flex: "1 1 0",
            height: 1,
            background: "rgba(108.11, 113.95, 116.88, 0.50)",
          }}
        />
        <div
          style={{
            color: "rgba(108.11, 113.95, 116.88, 0.50)",
            fontSize: 12,
            fontFamily: "Poppins",
            fontWeight: "500",
            lineHeight: 20,
            wordWrap: "break-word",
          }}
        >
          OR
        </div>
        <div
          style={{
            flex: "1 1 0",
            height: 1,
            background: "rgba(108.11, 113.95, 116.88, 0.50)",
          }}
        />
      </div>
      {/* format */}
      <form onSubmit={handleSubmit(onFormSubmit)} className="form-fields mt-32">
        <div className="w-100">
          <Input
            name="email"
            placeholder="Enter Your Email address"
            icon={emailIcon}
            disabled={socialId || googleEmail}
            errors={errors}
            register={register("email", {
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Please enter a valid Email",
              },
              required: {
                value: true,
                message: "This is required",
              },
              onChange: EmailVerify,
            })}
          />
        </div>
        <Button
          disabled={errors?.email?.message}
          className="custom-btn mb-24 mt-24"
          variant="primary"
          type="submit"
        >
          Next
        </Button>
        {children}
      </form>
    </>
  );
};

export default Step1;
