import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import life from "./../../assets/icons/lifeGroup.png";
import SignUp from "../../components/signup/SignUp";
import dSocialBack from "./../../assets/icons/dSocialBack.png";
import l1feMinistry from "./../../assets/images/l1feMinistry.png";
import { useLocation, useNavigate } from "react-router-dom";
import dSocial from "../../assets/icons/sharedlogo.jpg";
const SignupPage = () => {
  const navigation = useNavigate();

  return (
    <>
      <Container fluid className="vh-100 login-wrapper wrapper">
        <Row className="h-100">
          <Col xl={4} lg={5} md={6} className="left-col d-none d-md-block">
            <div className="w-50 mx-auto text-center left-inner-block d-flex flex-column align-items-center justify-content-end h-100">
              <img className="img-social-back" src={dSocialBack} alt="back" />
              <h1>Here is your voice back</h1>
              <div className="ministry-block">
                <img 
                  src={l1feMinistry} 
                  alt="l1fe_ministry" 
                  style={{ width: '150px', height: '41px' }} 
                />
              </div>
            </div>
          </Col>
          <Col xl={8} lg={7} md={6} className="right-col">
            <Container className="h-100">
              <Row className="h-100">
                <Col lg={{ span: 6, offset: 3 }} className="auth-block">
                  <div className="w-100">
                  <div className="icon-block">
                    <img 
                      src={life} 
                      alt="life" 
                      className="tab_desk" 
                      style={{ width: '100px'}}
                    />
                    <img src={dSocial} alt="life-logo" className="res_icon" />
                    </div>

                    <div className="tab-header">
                      <div class="head">
                        <div
                          className="tab"
                          onClick={() => navigation("/login")}
                        >
                          Login
                        </div>
                        <div
                          className={"active tab"}
                          onClick={() => navigation("/signup")}
                        >
                          Create lifeID
                        </div>
                      </div>
                    </div>

                    <SignUp />
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SignupPage;
